import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, GridRowsProp, GridColDef, gridClasses, GridSelectionModel, GridRowHeightParams } from '@mui/x-data-grid';
import { Chip, Rating, Box, Button, Typography, TextField, Checkbox, Tooltip } from '@mui/material';
import IconWatch from '../assets/ic-watchlist.png';
import IconWatchAct from '../assets/ic-watchlist-act.png';
import Image from '../components/Image'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import shallow from 'zustand/shallow'
import { useCounterStore, useTableStore, userTokenStore, useSearchStore, useWatchlistStore } from '../store'
import { padding } from '@mui/system';
import { ConstructionOutlined } from '@mui/icons-material';

import {
  typeOfConsultant,
  typeOfDesigner,
  typeOfContractorSubContractor,
  typeOfSupplierDealeFactory,
  typeOfOther
} from '../pages/home/StaticData'

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#F7F5F5',
    '&:hover, &.Mui-hovered': {
      backgroundColor: '#E5E5E5',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
  '& .MuiDataGrid-cell': { outline:'none!important'},
  '& .MuiDataGrid-cellContent, & .MuiBox-root':{
    fontSize: '15px',
    color:'#4F4F4F',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
    display: "none"
  },
}));




export default function DataGridTable({ requestData = (search_criteria?: string) => {} }) {
  const addWatchlistInputRef = useRef<HTMLInputElement>(null);

  const { 
    table, 
    total, 
    current_page, 
    next_page, 
    page, 
    saveTable, 
    saveTotal, setPage, 
    search, 
    selectedRow, 
    setSelectedRow, 
    isSelectAllRow, 
    setIsSelectAllRow,
    sortModel,
    setSortModel, 
  } = useTableStore(
    (state) => ({
      table: state.table,
      total: state.total,
      current_page: state.current_page,
      next_page: state.next_page,
      page: state.page,
      saveTable: state.saveTable,
      saveTotal: state.saveTotal,
      setPage: state.setPage,
      search: state.search,
      selectedRow: state.selectedRow,
      setSelectedRow: state.setSelectedRow,
      isSelectAllRow: state.isSelectAllRow,
      setIsSelectAllRow: state.setIsSelectAllRow,
      sortModel: state.sortModel,
      setSortModel: state.setSortModel,
    }),
    shallow
  )

  const {
    brand,
    keyword,
    project,
    vendor,
    typeOfBusiness,
    doneFlag,
    filter,
    pageSize,
    setPageSize,
    setByJson,
    projectWithMQDC,
    setTypeOfBusiness,
    setVendor,
    setSearchSnap
  } = useSearchStore(
    (state) => ({
      // searchSnap
      brand: state.searchSnap.brand,
      keyword: state.searchSnap.keyword,
      project: state.searchSnap.project,
      vendor: state.searchSnap.vendor,
      typeOfBusiness: state.searchSnap.typeOfBusiness,
      doneFlag: state.searchSnap.doneFlag,
      projectWithMQDC: state.searchSnap.projectWithMQDC,
      // end searchSnap
      filter: state.filter,
      pageSize: state.pageSize,
      setPageSize: state.setPageSize,
      setByJson: state.setByJson,
      setTypeOfBusiness: state.setTypeOfBusiness,
      setVendor: state.setVendor,
      setSearchSnap: state.setSearchSnap,
    })
  )

  const {
    addWatchlist,
    watchlistDatas,
  } = useWatchlistStore(
    (state) => ({
      addWatchlist: state.addWatchlist,
      watchlistDatas: state.datas,
    })
  )

  const token = userTokenStore((state) => state.token)

  const [pageState, setPageState] = useState(current_page)

  useEffect(() => {
    if (current_page !== pageState) {
      getData(pageSize)
    }
  }, [pageState])

  interface StringMap { [key: string]: string; }

  const columnRef: StringMap = {
    c0: 'No', 
    c1: 'Company Name', 
    c2: 'Contact Person',
    c3: 'Mobile No.',
    c4: 'Email',
    c5: 'Web Site',
    c6: 'Address',
    c7: 'Rating'
  }

  const getData = (pageSizeArg: any, sortObj?: any) => {
    const sortModelData = sortModel?.[0]
    const currentSort = sortModelData ? { sortBy: columnRef[sortModelData?.field], sortType: sortModelData.sort.toUpperCase() } : {}
    const searchCrt = {
      // search
      brand,
      keyword,
      project,
      vendor,
      typeOfBusiness,
      projectWithMQDC,
      doneFlag,
      //
      page: pageState,
      filter,
      pageSize: pageSizeArg,
      ...sortObj ? sortObj: currentSort,
    }
    setByJson(JSON.stringify({
      keyword,
      vendor,
      brand,
      project,
      typeOfBusiness,
      doneFlag,
      projectWithMQDC,
    }))
    search(searchCrt, token)
  }

  const [editDisplayName, setEditDisplayName] = React.useState<string | false>('');
  const [watchlistPayload, setWatchlistPayload] = React.useState({});
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalDuplicateWatchList, setOpenModalDuplicateWatchList] = React.useState(false)
  const handleModalOpen = (text:any, id: any) => {
    setOpenModal(true);
    setEditDisplayName(text);
    setWatchlistPayload({
      supplier_id: id,
    })
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const onSubmitWatchlist = async () => {
    const name = addWatchlistInputRef?.current?.value || '-'
    const checkWatchList = await addWatchlist({
      ...watchlistPayload,
      supplier_watchlist_name: name,
    }, token)
    if (!checkWatchList) setOpenModalDuplicateWatchList(true)
    else handleModalClose()
  }

  const handleOnClickAddWatchlist = (params: any) => {
    const id = params.id
    handleModalOpen(params.row.c2, id)
  }

  const selectTag = (tag: string) => {
    const typeList: any = {
      'Consultant': typeOfConsultant,
      'Designer': typeOfDesigner,
      'Contractor / Sub-Contractor': typeOfContractorSubContractor,
      'Supplier / Dealer': typeOfSupplierDealeFactory,
      'Other': typeOfOther
    }
    for (const key in typeList) {
      const type = typeList[key]
      const thisType = type.some((item: any) => item.value.includes(tag))
      if (thisType) {
        setVendor(key)
        setTypeOfBusiness([...typeOfBusiness, tag])
        break
      }
    }
    const searchCriteria = {
      typeOfBusiness: [...typeOfBusiness, tag],
      vendor: keyword
    }
    requestData(JSON.stringify(searchCriteria))
  }

  let rows: GridRowsProp = [
    { id: 1, c0:1, c1: 'บริษัท ริเน็น ดีเวลลอปเม้นท์ จำกัด', c2: 'Kathawut Kumpor',c3: '012-345-6789',c4: 'support@rinen.dev',c5: 'rinen.dev',c6: '1/23  อาคาร 456 หมู่7 แขวงหก เขตเจ็ด กรุงเทพมหานคร 10000',c7: '3.9',c8: 'off',c9: 'kitchen, bedroom, build-in, office' },
    { id: 2, c0:2, c1: 'บจก. คอมพานี เซอร์วิส', c2: 'Kathawut Kumpor',c3: '012-345-6789',c4: 'support@rinen.dev',c5: 'rinen.dev',c6: '2/23  อาคาร 456 หมู่7 แขวงหก เขตเจ็ด กรุงเทพมหานคร 10000',c7: '2.9',c8: 'off',c9: 'kitchen, bedroom, build-in, office' },
    { id: 3, c0:3, c1: 'บจก. คอมพานี เซอร์วิส', c2: 'Kathawut Kumpor',c3: '012-345-6789',c4: 'support@rinen.dev',c5: 'rinen.dev',c6: '3/23  อาคาร 456 หมู่7 แขวงหก เขตเจ็ด กรุงเทพมหานคร 10000',c7: '5',c8: 'off',c9: 'kitchen, bedroom, build-in, office' },
    { id: 4, c0:4, c1: 'บริษัท ริเน็น ดีเวลลอปเม้นท์ จำกัด', c2: 'AAA',c3: '012-345-6789',c4: 'supportaa@rinen.dev',c5: 'rinen.dev',c6: '4/23  อาคาร 456 หมู่7 แขวงหก เขตเจ็ด กรุงเทพมหานคร 10000',c7: '4.7',c8: 'off',c9: 'kitchen, bedroom, build-in, office' },
    { id: 5, c0:5, c1: 'บจก. คอมพานี เซอร์วิส', c2: 'BBB',c3: '012-345-6789',c4: 'supportbb@rinen.dev',c5: 'rinen.dev',c6: '5/23  อาคาร 456 หมู่7 แขวงหก เขตเจ็ด กรุงเทพมหานคร 10000',c7: '4.2',c8: 'off',c9: 'kitchen, bedroom, build-in, office' },
    { id: 6, c0:6, c1: 'บจก. คอมพานี เซอร์วิส', c2: 'CCC',c3: '012-345-6789',c4: 'supportcc@rinen.dev',c5: 'rinen.dev',c6: '6/23  อาคาร 456 หมู่7 แขวงหก เขตเจ็ด กรุงเทพมหานคร 10000',c7: '4.7',c8: 'off',c9: 'kitchen, bedroom, build-in, office' },
    { id: 7, c0:7, c1: 'บริษัท ริเน็น ดีเวลลอปเม้นท์ จำกัด', c2: 'DDD',c3: '012-345-6789',c4: 'supportdd@rinen.dev',c5: 'rinen.dev',c6: '7/23  อาคาร 456 หมู่7 แขวงหก เขตเจ็ด กรุงเทพมหานคร 10000',c7: '3.9',c8: 'on',c9: 'kitchen, bedroom, build-in, office' },
    { id: 8, c0:8, c1: 'บจก. คอมพานี เซอร์วิส', c2: 'EEE',c3: '012-345-6789',c4: 'supportee@rinen.dev',c5: 'rinen.dev',c6: '8/23  อาคาร 456 หมู่7 แขวงหก เขตเจ็ด กรุงเทพมหานคร 10000',c7: '5',c8: 'on',c9: 'kitchen, bedroom, build-in, office' },
    { id: 9, c0:9, c1: 'บจก. คอมพานี เซอร์วิส', c2: 'FFF',c3: '012-345-6789',c4: 'supportff@rinen.dev',c5: 'rinen.dev',c6: '9/23  อาคาร 456 หมู่7 แขวงหก เขตเจ็ด กรุงเทพมหานคร 10000',c7: '4.1',c8: 'on',c9: 'kitchen, bedroom, build-in, office' },
    { id: 10, c0:10, c1: 'บริษัท ริเน็น ดีเวลลอปเม้นท์ จำกัด', c2: 'GGG',c3: '012-345-6789',c4: 'supportgg@rinen.dev',c5: 'rinen.dev',c6: '10/23  อาคาร 456 หมู่7 แขวงหก เขตเจ็ด กรุงเทพมหานคร 10000',c7: '5',c8: 'on',c9: 'kitchen, bedroom, build-in, office' },
    { id: 11, c0:11, c1: 'บจก. คอมพานี เซอร์วิส', c2: 'HHH',c3: '012-345-6789',c4: 'supporthh@rinen.dev',c5: 'rinen.dev',c6: '11/23  อาคาร 456 หมู่7 แขวงหก เขตเจ็ด กรุงเทพมหานคร 10000',c7: '4.7',c8: 'off',c9: 'kitchen, bedroom, build-in, office' },
    { id: 12, c0:12, c1: 'บจก. คอมพานี เซอร์วิส', c2: 'III',c3: '012-345-6789',c4: 'supportii@rinen.dev',c5: 'rinen.dev',c6: '13/23  อาคาร 456 หมู่7 แขวงหก เขตเจ็ด กรุงเทพมหานคร 10000',c7: '3.9',c8: 'ofon',c9: 'kitchen, bedroom, build-in, office' },
    { id: 13, c0:13, c1: 'บจก. คอมพานี เซอร์วิส', c2: 'HHH',c3: '012-345-6789',c4: 'supporthh@rinen.dev',c5: 'rinen.dev',c6: '11/23  อาคาร 456 หมู่7 แขวงหก เขตเจ็ด กรุงเทพมหานคร 10000',c7: '4.7',c8: 'off',c9: 'kitchen, bedroom, build-in, office' },
    { id: 14, c0:14, c1: 'บจก. คอมพานี เซอร์วิส', c2: 'III',c3: '012-345-6789',c4: 'supportii@rinen.dev',c5: 'rinen.dev',c6: '13/23  อาคาร 456 หมู่7 แขวงหก เขตเจ็ด กรุงเทพมหานคร 10000',c7: '3.9',c8: 'ofon',c9: 'kitchen, bedroom, build-in, office' },
    { id: 15, c0:15, c1: 'บจก. คอมพานี เซอร์วิส', c2: 'HHH',c3: '012-345-6789',c4: 'supporthh@rinen.dev',c5: 'rinen.dev',c6: '11/23  อาคาร 456 หมู่7 แขวงหก เขตเจ็ด กรุงเทพมหานคร 10000',c7: '4.7',c8: 'off',c9: 'kitchen, bedroom, build-in, office' },
    { id: 16, c0:16, c1: 'บจก. คอมพานี เซอร์วิส', c2: 'III',c3: '012-345-6789',c4: 'supportii@rinen.dev',c5: 'rinen.dev',c6: '13/23  อาคาร 456 หมู่7 แขวงหก เขตเจ็ด กรุงเทพมหานคร 10000',c7: '3.9',c8: 'ofon',c9: 'kitchen, bedroom, build-in, office' },
  ];
  

  const columns: GridColDef[] = [
    { field: 'c0', headerName: 'No', align: 'center', width: 50,headerClassName: 'table-header-center',
    renderCell: (params) => {
      return (
          <Box sx={{ fontSize:'12px!important', maxWidth:'50px'}}>{params.value}</Box>
      )
    }},
    { field: 'c1', headerName: 'Company Name', flex: 1, minWidth: 280,
      // renderCell: (params) => {
      // return <Box sx={{whiteSpace:'normal'}}>{params.value}</Box>;}
      renderCell: (params) => {
        return (
          <Tooltip title={params.value} placement="top" arrow>
            <Box sx={{whiteSpace:'normal',width:'250px',overflow:'hidden',textOverflow:'ellipsis'}}>{params.value}</Box>
          </Tooltip>
        )
      }
      ,
      headerClassName: 'table-header-center',
    },
    { field: 'c2', headerName: 'Contact Person', flex: 1, width: 120 },
    { field: 'c3', headerName: 'Mobile No.', flex: 1, width: 30,
    renderCell: (params) => {
      return (
          <Box sx={{whiteSpace:'normal',fontSize:'12px!important'}}>{params.value}</Box>
      )
    }},
    { field: 'c4', headerName: 'Email', align: 'center', flex: 1, width: 70,
      headerClassName: 'table-header-center'
    },
    { field: 'c5', headerName: 'Web Site', align: 'center', flex: 1, width: 125, headerClassName: 'table-header-center'},
    { field: 'c6', headerName: 'Address', flex: 1, minWidth: 280, 
      renderCell: (params) => {
        return (
          <Tooltip title={params.value} placement="top" arrow>
            <Box sx={{whiteSpace:'normal',minWidth:'280px',overflow:'hidden',textOverflow:'ellipsis'}}>{params.value}</Box>
          </Tooltip>
        )
      }, headerClassName: 'table-header-center',
    },
    // { field: 'c7', headerName: 'Rating', align: 'center',
    //   renderCell: (params) => {
    //   return <Rating name="read-only" size="small" value={params.row.c7} readOnly />;}
    //   , minWidth: 100 },
    { field: 'c7', headerName: 'Rating', align: 'center', flex: 1, width: 35, headerClassName: 'table-header-center'},
    { field: 'c8', headerName: '', align: 'center', width: 35,
      renderCell: (params) => {
        const isActive = watchlistDatas.findIndex((item: any) => item.supplier_id === params.id) !== -1
        return !isActive ? (
          <Box 
            onClick={() => handleOnClickAddWatchlist(params)}
          >
            <Image src={IconWatch} height="12"  />
          </Box> 
        ) : (
          <Box>
            <Image src={IconWatchAct} height="12" />
          </Box>
        );},
        sortable: false
    },
    { field: 'c9', headerName: '',
      renderCell: (params) => {
        const myArray = params.row.c9.split(",");
        const myArrayLength = myArray.length
        var showLength = 7
        if(myArrayLength === 3){
          showLength = 7
        }else if(myArrayLength === 4){
          showLength = 4
        }else if(myArrayLength === 5){
          showLength = 3
        }else if(myArrayLength > 5){
          showLength = 1
        }
        return (
          <Box sx={{display:'flex', flexFlow:'row wrap', gap:'4px', overflow:'auto', minWidth:'300px'}}>
            {myArray.map(((item: string) => {
              if (!item.length) return null
                return (
                  <Tooltip title={item} placement="top" arrow>
                    <Chip 
                      label={item}
                      // label={myArrayLength > 2 && item.length > showLength ? `${item.trim().substring(0, showLength)}...` : item.trim()}
                      size="small" 
                      sx={{
                        backgroundColor: '#FFAD61',
                        color: '#4F4F4F',
                        fontSize: '12px',
                        lineHeight: '14px',
                        height: '16px',
                        padding: '0',
                      }}
                      onClick={() => selectTag(item)}
                    />
                  </Tooltip>
                )
            }))}
          </Box>
        );}
      ,
      // valueGetter: (params: GridValueGetterParams) =>
      //   `${params.row.c1 || ''} ${params.row.c2 || ''}`,
      flex: 1,
      minWidth: 300,
      sortable: false
     }
  ];

  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>(selectedRow);

  useEffect(() => {
    const shouldSet = [...selectionModel].join('') !== selectedRow.join('')
    if (shouldSet) {
      setSelectedRow([...selectionModel])
    }
  }, [selectionModel])

  const onSort = (sortDatas: any, details: any) => {
    const data = sortDatas?.[0]
    setSortModel(data ? [data] : [])
    const sortObj = data ? { sortBy: columnRef[data?.field], sortType: data.sort.toUpperCase() } : {}
    getData(pageSize, sortObj)
  }

  return (
    <>
    {/* {page}, {next_page} */}
    <div style={{ height: 'calc(100vh - 425px)', minHeight:'400px', width: '100%', position: 'relative' }}>
      {/* <DataGrid rows={rows} columns={columns} /> */}
        <StripedDataGrid
          rows={table} 
          columns={columns}
          disableColumnFilter
          disableColumnMenu
          rowsPerPageOptions={[10, 30, 50, 100]}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          disableSelectionOnClick
          sx={{
            fontSize: '16px',
            background:'#fff',
            "& .MuiDataGrid-selectedRowCount": {
              opacity: isSelectAllRow ? 0 : 1
            },
          }}        
          rowCount={total}
          pageSize={pageSize}
          page={pageState}
          filterMode="server"
          onFilterModelChange={() => {

          }}
          paginationMode="server"
          onPageChange={(newPage) => setPageState(newPage)}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize)
            getData(newPageSize)
          }}
          checkboxSelection
          selectionModel={selectionModel}
          keepNonExistentRowsSelected
          onSelectionModelChange={setSelectionModel}
          components={{
            BaseCheckbox: ({ onChange, checked }) => {
              return (<Checkbox checked={checked && !isSelectAllRow} onChange={onChange} disabled={isSelectAllRow} />)
            }
          }}
          sortingMode="server"
          onSortModelChange={onSort}
          initialState={{
            sorting: {
              sortModel: sortModel,
            },
          }}
          getRowHeight={({ id, densityFactor }: GridRowHeightParams) => 70 * densityFactor}
        />
        <div
          style={{
            position: 'absolute',
            top: 8,
            left: 1,
            backgroundColor: '#fff',
            padding:  '0px 0px 0px 4px',
          }}
        >
          <Checkbox 
            checked={isSelectAllRow}
            onChange={() => {
              setIsSelectAllRow(!isSelectAllRow)
              setSelectionModel([])
            }} 
          />
        </div>
    </div>
    <Dialog 
    open={openModal} 
    onClose={handleModalClose}
    sx={modalStyle}
    >
        <DialogTitle>
          <Typography component='p' sx={{m:0}}>Add new watchlist</Typography>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: 'absolute',
              right: 3,
              top: 0,
              color: '#4F4F4F',
              '& svg':{ fontSize:'14px',}
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              autoFocus
              id="fav_name"
              placeholder='Watchlist name'
              defaultValue={editDisplayName!=='' ? editDisplayName : ''}
              inputProps={{ maxLength: 100 }}
              fullWidth
              inputRef={addWatchlistInputRef}
              sx={{
                '& input':{height:'25px'},
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {borderWidth:'1px', borderColor:'#A2A2A2'}
             }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{display:'flex', justifyContent:'center', alignItems:'center', p:0 , mb:3}}>
          <Button variant="contained" onClick={onSubmitWatchlist}>Submit</Button>
        </DialogActions>
    </Dialog>

    <Dialog 
    open={openModalDuplicateWatchList} 
    onClose={handleModalClose}
    sx={modalStyle}
    >
        <DialogTitle>
          <Typography component='p' sx={{m:0}}>Warning duplicate</Typography>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: 'absolute',
              right: 3,
              top: 0,
              color: '#4F4F4F',
              '& svg':{ fontSize:'14px',}
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Given name was duplicated</DialogContentText>
        </DialogContent>
        <DialogActions sx={{display:'flex', justifyContent:'center', alignItems:'center', p:0 , mb:3}}>
          <Button variant="contained" onClick={() => setOpenModalDuplicateWatchList(false)}>OK</Button>
        </DialogActions>
    </Dialog>
    </>
  );
}

const modalStyle = {
  '& .MuiDialog-paper': {
    minWidth:'431px',
    maxWidth:'100%',
    padding:0,
    borderRadius:'5px',
  },
  '& .MuiDialogTitle-root': {
    padding:'0 10px',
    background:'#FFBD52',
    marginBottom: '16px',
    height: '31px',
    lineHeight: '31px',
    '& .MuiTypography-root': {
      fontSize:'14px',
      fontWeight:'400',
      color:'#000',
      lineHeight: '31px',
    }
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {borderWidth:'1px!important', borderColor:'#A2A2A2!important'},
  '& .MuiButton-root': {
    width:'150px',
    height:'35px',
    backgroundColor:'#FFBD52',
    color:'#fff',
    borderRadius:'5px'
  }
}
