import create from 'zustand'
import { persist } from 'zustand/middleware'
import axios from "axios"

// const counterStore = (set) => ({
//   count: 0,
//   increment: () => set((state) => ({ count: state.count + 1 })),
//   decrement: () => set((state) => ({ count: state.count - 1 }))
// })
const BASE_URL = `https://api-uat-supplierlist.dtgo.com`
const counterStore = persist(
    (set) => ({
      count: 0,
      increment: () => set((state) => ({ count: state.count + 1 })),
      decrement: () => set((state) => ({ count: state.count - 1}))
    }),
    { name: 'my-counter' }
  )
export const useCounterStore = create(counterStore)

const themeStore = persist(
  (set) => ({
    color: '#222',
    backgroundColor: '#ff0000',
    setColor: (color) => set(() => ({ color })),
    setBackgroundColor: (color) => set(() => ({ backgroundColor: color }))
  }),
  { name: 'my-theme' }
)

export const useThemeStore = create(themeStore)

const tableDataStore = persist(
    (set) => ({
      total: 0,
      next_page: 0,
      current_page: 0,
      page: 0,
      table: [],
      isSelectAllRow: false,
      selectedRow: [],
      sortModel: [],
      setSortModel: (sortModel) => set(() => ({ sortModel })),
      setSelectedRow: (selectedRow) => set(() => ({ selectedRow })),
      setIsSelectAllRow: (isSelectAllRow) => set({ isSelectAllRow }),
      saveTotal: (total) => set(() => ({ total: total })),
      setPage: (page) => set(() => ({ page: page })),
      savePage: (page) => set(() => ({ page: page })),
      saveTable: (table) => {
        alert('table data : ')
        set(() => ({ table: table }))
      },
      search: (dataSearchCrt, token) => {
        set(() => ({ table: [] }))
        set(() => ({ total: 0 }))
        console.log('dataSearchCrt::', dataSearchCrt)
        axios.post(BASE_URL+"/api/v1/search", dataSearchCrt).then(response => {
          console.log(response.data)
          if(response && response.data.statuscode === 0){
            set(() => ({ table: response.data.data.result_data }))
            set(() => ({ total: response.data.data.total }))
            set(() => ({ current_page: response.data.data.current_page }))
            set(() => ({ next_page: response.data.data.next_page }))
          }
         })
      }
    }),
    { name: 'table-data' }
  )
export const useTableStore = create(tableDataStore)
  const initialData = {
    keyword: '',
    vendor: '',
    brand: [],
    project: '',
    typeOfBusiness: [],
    doneFlag: '',
    projectWithMQDC: [],
  }

  const searchStore = persist(
    (set) => ({
      ...initialData,
      filter: '',
      pageSize: 10,
      searchSnap: {
        ...initialData,
      },
      setSearchSnap: (searchSnap) => set({ searchSnap }),
      setKeyword: (keyword) => set(() => ({ keyword })),
      setVendor: (vendor) => set(() => ({ vendor })),
      setBrand: (brand) => set(() => ({ brand })),
      setProject: (project) => set(() => ({ project })),
      setTypeOfBusiness: (typeOfBusiness) => set(() => ({ typeOfBusiness })),
      setDoneFlag: (doneFlag) => set(() => ({ doneFlag })),
      setProjectWithMQDC: (projectWithMQDC) => set(() => ({ projectWithMQDC })),
      reset: () => set(initialData),
      setFilter: (filter) => set(() => ({ filter })),
      setPageSize: (pageSize) => set(() => ({ pageSize })),
      setByJson: (jsonString) => {
        try {
          const filter = JSON.parse(jsonString)
          return set(() => ({ ...filter }))
        } catch (e) {
          console.warn(e)
        }
      }
    }),
    { name: 'search-crt' }
  )
  export const useSearchStore = create(searchStore)

  const tokenStore = persist(
    (set) => ({
      token: '',
      setToken: (token) => set(() => ({ token })),
    }),
    { name: 'token-user' }
  )
  export const userTokenStore = create(tokenStore)

  const fetchWatchlistData = (set, token) => {
    return axios.get(BASE_URL+"/api/v1/watchlist").then(response => {
      if(response && response.data){
        set(() => ({ datas: response.data }))
      }
     })
  }

  const watchlistStore = persist(
    (set) => ({
      datas: [],
      setDatas: (datas) => set(() => ({ datas })),
      getDatas: (token) => {
        return fetchWatchlistData(set, token)
      },
      addWatchlist: (payload, token) => {
        return axios.post(BASE_URL+"/api/v1/watchlist", payload).then(response => {
          if(response && response.data.statuscode === 0) {
            fetchWatchlistData(set, token)
            return true
          }
          return false
        })
      },
      deleteWatchlist: (id, token) => {
        return axios.delete(BASE_URL+"/api/v1/watchlist/"+id).then(response => {
          if(response && response.data.statuscode === 0) {
            fetchWatchlistData(set, token)
          }
        })
      },
      editWatchlist: (id, payload, token) => {
        return axios.put(BASE_URL+"/api/v1/watchlist/"+id, payload).then(response => {
          if(response && response.data.statuscode === 0) {
            fetchWatchlistData(set, token)
            return true
          }
          return false
        })
      }
    }),
    { name: 'watchlist-data' }
  )
  export const useWatchlistStore = create(watchlistStore)

  const fetchFavoriteData = (set, token) => {
    return axios.get(BASE_URL+"/api/v1/favorite").then(response => {
      if(response && response.data){
        set(() => ({ datas: response.data }))
      }
     })
  }

  const favoriteStore = persist(
    (set) => ({
      datas: [],
      getDatas: (token) => {
        return fetchFavoriteData(set, token)
      },
      addFavorite: (payload, token) => {
        console.log('payload::', payload)
        return axios.post(BASE_URL+"/api/v1/favorite", payload).then(response => {
          if(response && response.data.statuscode === 0) {
            fetchFavoriteData(set, token)
            return true
          }
          return false
        })
      },
      deleteFavorite: (id, token) => {
        return axios.delete(BASE_URL+"/api/v1/favorite/"+id).then(response => {
          if(response && response.data.statuscode === 0) {
            fetchFavoriteData(set, token)
          }
        })
      },
      editFavorite: (id, payload, token) => {
        console.log('payload', payload)
        return axios.put(BASE_URL+"/api/v1/favorite/"+id, payload).then(response => {
          if(response && response.data.statuscode === 0) {
            fetchFavoriteData(set, token)
            return true
          }
          return false
        })
      }
    }),
    { name: 'favorite-data' }
  )
  export const useFavoriteStore = create(favoriteStore)