import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#E9FFEF',
      main: '#FFBD52',
      dark: '#4F4F4F',
    },
    error: {
      main: '#D21100',
    },
    background: {
      default: '#F4F8F9',
    },
    info: {
      main: '#00A9DE',
      light: '#B3ECFE',
    },
  },
  typography: {
    fontFamily: 'Noto Sans Thai',
    h1 : {
      fontWeight: '600',
    },
    h2 : {
      fontWeight: '600',
    },
    h3 : {
      fontWeight: '600',
    },
    h4 : {
      fontWeight: '600',
    },
    h5 : {
      fontWeight: '600',
    },
    h6 : {
      fontWeight: '600',
    },
    subtitle1: {
      fontWeight: '600',
    },
    body2: {
      color: '#000000',
    },
  },
  shape: {
    borderRadius: 2,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          paddingTop: '10px',
          paddingBottom: '10px',
          borderRadius: '5px',
          boxShadow: 'none',
          fontSize: '14px',
          whiteSpace: 'nowrap',
          '& p': {
            lineHeight: '1.61',
          },
          '& .MuiButton-endIcon': {
            marginLeft: '12px',
          },
          '& .MuiButton-startIcon': {
            marginRight: '12px',
          },
          textTransform: 'none',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: '#36C690',
          color: '#fff',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: '0',
        },
        input: {
          padding: '2px 12px 2px 12px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '0',
          borderRadius: '5px',
          background:'#fff',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#A2A2A2',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {borderColor: '#A2A2A2',}
        },
        input: {
          padding: '5px',
          borderRadius: '5px',
          fontSize: '14px',
        },
        
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          paddingTop: '0px',
        },
        item: {
          paddingTop: '0px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          marginBottom: '0px',
          textTransform: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          padding: '10px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
          root: {
            '&.Mui-selected, &:hover':{
              backgroundColor: 'rgba(255, 189, 82, 0.8)!important',
            }
          },
      }
    },
  }
});

export default theme;
