import React, { useEffect, useState, useRef } from 'react'
// import { useTheme } from '@mui/material/styles';
// import { DataGrid, GridRowsProp, GridColDef, gridClasses } from '@mui/x-data-grid';
import { Box, Button, TextField, Grid, MenuItem, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

// import { useAppSelector, useAppDispatch } from '../../store/store';
// import { clearDataFilter, updateAddItemsState } from '../../store/slices/add-items-slice';
import Placeholder from '../../components/Placeholder';
import IconSearch from '../../assets/ic-search.png';
import IconExport from '../../assets/ic-export.png';
import Image from '../../components/Image'
import TableDataGrid from '../../components/TableDataGrid';
import FavWatchListBox from '../../components/FavWatchListBox';
import FavoriteIcon from '@mui/icons-material/Favorite';
import axios from "axios"
import shallow from 'zustand/shallow'
import { useTableStore, useSearchStore, userTokenStore, useFavoriteStore } from '../../store'
import { 
  typeOfVender,
  typeOfConsultant,
  typeOfDesigner,
  typeOfContractorSubContractor,
  typeOfSupplierDealeFactory,
} from './StaticData'

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';

// const isAuthenticated = useIsAuthenticated();

const BASE_URL = `https://api-uat-supplierlist.dtgo.com`
const HomePage = () => {

  const { instance, accounts } = useMsal();
  axios.defaults.headers.common['username'] = accounts && accounts.length > 0 ? accounts[0].username : 'anonymous'

  const [brandList, setBrandList] = React.useState([])
  const [projectList, setProjectList] = React.useState([])

  const keywordRef = useRef<HTMLInputElement>(null);
  const projectReferenceRef = useRef<HTMLInputElement>(null);
  const businessOtherRef = useRef<HTMLInputElement>(null);
  const favNameInputRef = useRef<HTMLInputElement>(null);
  const filterInputRef = useRef<HTMLInputElement>(null);

  const [favFilter, setFavFilter] = useState(JSON.stringify({}))

  useEffect(() => {
    axios
      .get(BASE_URL + "/api/v1/brand")
      .then((res) => {
        setBrandList(res.data.data.result_data)
      })
    axios
      .get(BASE_URL + "/api/v1/project")
      .then((res) => {
        setProjectList(res.data.data.result_data)
      })
    requestData()
  }, [])

  // const theme = useTheme();
  // const dispatch = useAppDispatch();
  // const addItemSlice = useAppSelector((state) => state.addItemSlice);
  const { table, saveTable, saveTotal, search, selectedRow, setSelectedRow, setIsSelectAllRow, setSortModel } = useTableStore(
    (state) => ({
      table: state.table,
      saveTable: state.saveTable,
      saveTotal: state.saveTotal,
      search: state.search,
      selectedRow: state.selectedRow,
      setSelectedRow: state.setSelectedRow,
      setIsSelectAllRow: state.setIsSelectAllRow,
      setSortModel: state.setSortModel,
    }),
    shallow
  )
  
  const {
    setKeyword,
    setVendor,
    setBrand, 
    setProject, 
    setTypeOfBusiness, 
    setDoneFlag, 
    setProjectWithMQDC,
    brand: brandData,
    keyword: keywordData,
    project,
    reset,
    vendor,
    typeOfBusiness,
    doneFlag,
    projectWithMQDC,
    setFilter,
    filter,
    pageSize,
    setSearchSnap,
    searchSnap,
  } = useSearchStore(
    (state) => ({
      setKeyword: state.setKeyword,
      setVendor: state.setVendor,
      setBrand: state.setBrand,
      setProject: state.setProject,
      setTypeOfBusiness: state.setTypeOfBusiness,
      setDoneFlag: state.setDoneFlag,
      setProjectWithMQDC: state.setProjectWithMQDC,
      brand: state.brand,
      keyword: state.keyword,
      project: state.project,
      reset: state.reset,
      vendor: state.vendor,
      typeOfBusiness: state.typeOfBusiness,
      doneFlag: state.doneFlag,
      projectWithMQDC: state.projectWithMQDC,
      setFilter: state.setFilter,
      filter: state.filter,
      pageSize: state.pageSize,
      setSearchSnap: state.setSearchSnap,
      searchSnap: state.searchSnap,
    }),
    shallow
  )
  
  const { token, setToken } = userTokenStore(
    (state) => ({
      token: state.token,
      setToken: state.setToken
    }),
    shallow
  )

  const { 
    addFavorite,
  } = useFavoriteStore(
    (state) => ({
      addFavorite: state.addFavorite,
    }),
    shallow
  )

  const renderTypeOfBusiness = () => {
    switch(vendor) { 
      case 'Consultant': { 
        return (
          <Select 
            multiple
            displayEmpty
            sx={textFieldStyle}
            value={typeOfBusiness || []}
            onChange={(event) => onChangeTypeOfBusiness(event)}
            renderValue={(selected) => {
              if (selected.length === 0) return <span style={{color: '#aaa'}}>กรุณาเลือก</span>
              return selected.join(', ')
            }}
          >
            {typeOfConsultant.map((item) => (
              <MenuItem key={item.key} value={item.value}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
        )
      }
      case 'Designer': { 
        return (
          <Select
            multiple
            displayEmpty
            sx={textFieldStyle}
            value={typeOfBusiness || []}
            onChange={(event) => onChangeTypeOfBusiness(event)}
            renderValue={(selected) => {
              if (selected.length === 0) return <span style={{color: '#aaa'}}>กรุณาเลือก</span>
              return selected.join(', ')
            }}
          >
            {typeOfDesigner.map((item) => (
              <MenuItem key={item.key} value={item.value}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
        )
      }
      case 'Contractor / Sub-Contractor': { 
        return (
          <Select
            multiple
            displayEmpty
            sx={textFieldStyle}
            value={typeOfBusiness || []}
            onChange={(event) => onChangeTypeOfBusiness(event)}
            renderValue={(selected) => {
              if (selected.length === 0) return <span style={{color: '#aaa'}}>กรุณาเลือก</span>
              return selected.join(', ')
            }}
          >
            {typeOfContractorSubContractor.map((item) => (
              <MenuItem key={item.key} value={item.value}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
        )
      }
      case 'Supplier / Dealer': {
        return (
          <Select
            multiple 
            displayEmpty
            sx={textFieldStyle}
            value={typeOfBusiness || []}
            onChange={(event) => onChangeTypeOfBusiness(event)}
            renderValue={(selected) => {
              if (selected.length === 0) return <span style={{color: '#aaa'}}>กรุณาเลือก</span>
              return selected.join(', ')
            }}
          >
            {typeOfSupplierDealeFactory.map((item) => (
              <MenuItem key={item.key} value={item.value}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
        )
      }
      case 'Other': {
        return (
          <TextField
            placeholder="กรุณากรอก"
            inputProps={{ maxLength: 100 }}
            defaultValue={typeOfBusiness || []}
            inputRef={businessOtherRef}
            // onBlur={(e) => storeTypeOfBusiness(e.target.value)}
          />
        )
      }  
      default: { 
         return (
          <Select 
            sx={textFieldStyle}
            defaultValue={typeOfBusiness || []}
            displayEmpty
            disabled
          >
            <MenuItem disabled value=''>
              <Placeholder text="กรุณาเลือก" />
            </MenuItem>
          </Select>
         )
      } 
   } 
  }

  const onChangeTypeOfBusiness = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target
    formatSearchFilter()
    setTypeOfBusiness(value)
  }
  
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalDuplicateFavorite, setOpenModalDuplicateFavorite] = React.useState(false)
  const handleModalOpen = () => {
    setFavFilter(JSON.stringify(formatSearchFilter()))
    setOpenModal(true);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };
  React.useEffect(() => {
    // dispatch(clearDataFilter());
  }, []);

  const SearchBox = styled('div')(() => ({
    backgroundColor: '#f0f0f0',
    padding: '8px 8px 8px 20px',
    marginBottom: '16px',
    boxShadow:'0px 1px 2px rgba(0, 0, 0, 0.1)',
    borderRadius:'5px',
    // '& h2': {
    //   color: '#000',
    //   fontWeight: '600',
    //   fontSize: 24,
    //   margin: 0,
    // },
    // '& button': {
    //   width: '230px',
    //   maxWidth: '100%',
    // },
  }));
  const MainLeft = styled('div')(() => ({
    display: 'flex',
    // height: '100%',
    // padding: '15vh 15px 15px',
    '& button': {
      width: '60px',
      height: '35px',
      maxWidth: '100%',
      color: '#fff'
    },
    '& button[type=submit]': {
      width: '150px',
    },
    '& .close-button': {
      width: '20px',
      height: '20px',
      marginLeft: '10px'
    }
  }));

  const ContentBox = styled('div')(() => ({
    flex:1,
    backgroundColor: '#f0f0f0',
    padding: '8px',
    boxShadow:'0px 1px 2px rgba(0, 0, 0, 0.1)',
    borderRadius:'5px',

  }));

  function storeKeyword(value: string){
    setKeyword(value)
  }

  function storeVendor(value: string){
    formatSearchFilter()
    setVendor(value)
  }

  function storeBrand(event: SelectChangeEvent<string[]>) {
    const { value } = event.target
    formatSearchFilter()
    setBrand(value)
  }

  function storeProject(value: string){
    setProject(value)
  }

  function storeDoneFlag(value: string){
    formatSearchFilter()
    setDoneFlag(value)
  }

  function storeProjectWithMQDC(event: SelectChangeEvent<string[]>) {
    const { value } = event.target
    formatSearchFilter()
    setProjectWithMQDC(value)
  }

  const formatSearchFilter = (clear: boolean = false) => {
    const currentKeyword = clear ? '' : keywordRef?.current?.value || ''
    const currentProject = projectReferenceRef?.current?.value || ''
    storeKeyword(currentKeyword)
    storeProject(currentProject)
    let currentTypeOfBusiness = typeOfBusiness

    if (vendor === 'Other') {
      currentTypeOfBusiness = businessOtherRef?.current?.value || []
      setTypeOfBusiness(currentTypeOfBusiness)
    }

    const searchCrt = {
      page: 0,
      brand: brandData,
      keyword: currentKeyword,
      project: currentProject,
      vendor,
      typeOfBusiness: currentTypeOfBusiness,
      doneFlag,
      projectWithMQDC,
    }

    return searchCrt
  }

  const onSubmitAddFav = async () => {
    const name = favNameInputRef?.current?.value || '-'
    const payload = {
      search_criteria: favFilter,
      search_name: name,
    }
    const checkFavorite = await addFavorite(payload, token)
    if (!checkFavorite) setOpenModalDuplicateFavorite(true)
    else handleModalClose()
  }

  const getSearchCrt = (useSnap?: boolean, clear: boolean = false) => {
    const filter = filterInputRef?.current?.value
    const searchCrt = { 
      ...useSnap ? searchSnap : formatSearchFilter(clear), 
      ...filter ? { filter } : {},
      pageSize,
    }
    return searchCrt
  }

  const clearSearch = async () => {
    reset()
    const initialData = {
      keyword: '',
      vendor: '',
      brand: [],
      project: '',
      typeOfBusiness: [],
      doneFlag: '',
      projectWithMQDC: [],
    }
    await requestData(JSON.stringify(initialData), true)
  }

  function requestData(search_criteria?: string, clear: boolean = false){
    const searchCrt = getSearchCrt(false, clear)
    if (search_criteria) {
      const searchCriteria = JSON.parse(search_criteria)
      Object.assign(searchCrt, searchCriteria)
    }
    setSearchSnap(searchCrt)
    search(searchCrt, token)
    setIsSelectAllRow(false)
    setSortModel([])
    setSelectedRow([])
    // setToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.Kx5GM9JyYIMN2GVjXea6SkVs0uu9Rc00qiVynifkH8g')
  }
  let brand: object[]
  
  // axios.get(BASE_URL+"/api/v1/brand").then(response => {
  //   console.log(response.data)
  //   brand = response.data.data
  //  })  

  //  const menuItems = brand.map(item => (
  //   <MenuItem key={element.Key} value={element.Key}>{item.Value}</MenuItem>
  // ));

  const [keywordState, setKeywordState] = useState('')

  const downloadBlob = (fileName: string, blob: Blob) => {
    const anchor = window.document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
  }

  const download = async () => {
    const payload = getSearchCrt(true)
    return axios
      .post(
        BASE_URL + "/api/v1/search/export", 
        {
          ...payload,
          ...selectedRow?.length ? { ids: selectedRow?.map((item: string) => parseInt(item)) } : {},
        },
        {
          // headers:
          // {
          //     'Content-Disposition': "attachment; filename=template.xlsx",
          //     'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          // },
          responseType: 'arraybuffer',
        }
    )
      .then((res) => {
        const blobFile = new Blob([res?.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        downloadBlob('download', blobFile)
      })
  }

  return (
    <>
    {/* <p>Your age is {keyword}</p>
        
        <button onClick={changeKeyword}>Change Age</button>
        <br/>
        <input value={keyword} onChange={(e)=>setKeyword(e.target.value)} /> */}
        
    <Box sx={{display:'block', width:'100%', p:1, gap:1}}>
    
      <SearchBox>
        <Grid container columnSpacing={4} sx={{alignItems:'stretch', 
        // height:'100%',
          // minHeight:'22vh',
        }}>
          <Grid item xs={12} md={9}>
            <MainLeft>
                <Grid container rowSpacing={{ xs: 0.5, md: 1 }} columnSpacing={{ xs: 0.5, md: 1 }}>
                  {/* SEARCH TEXT */}
                  <Grid item xs={12} md={12} sx={gridStyle}>
                    <TextField
                      placeholder="Search ITEM/TASKNAME/BRAND..."
                      inputProps={{ maxLength: 100 }}
                      // ref="keyword"
                      defaultValue={keywordData}
                      inputRef={keywordRef}
                      // onBlur={(e) => storeKeyword(e.target.value)}
                    />
                  </Grid>

                  {/* VENDOR */}
                  <Grid item xs={12} md={6} sx={gridRowStyle}>
                    <Label>Type of vendor :</Label>
                    <Select 
                    sx={textFieldStyle}
                    defaultValue={vendor || ''}
                    displayEmpty
                    onChange={(e) => {
                      storeVendor(e.target.value)
                      setTypeOfBusiness([])
                    }}
                    >
                      <MenuItem disabled value=''>
                        <Placeholder text="กรุณาเลือก" />
                      </MenuItem>
                      {
                        typeOfVender.map((item) => (
                          <MenuItem key={item.key} value={item.value}>
                            {item.value}
                          </MenuItem>
                        ))
                      }
                    </Select>
                    <IconButton
                      className="close-button"
                      aria-label="close"
                      onClick={() => {
                        storeVendor('')
                        setTypeOfBusiness([])
                      }}
                      sx={closeButtonStyle}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>

                  {/* BRAND */}
                  <Grid item xs={12} md={6} sx={gridRowStyle}>
                    <Label>Brand :</Label>
                    <Select 
                      multiple
                      displayEmpty
                      sx={textFieldStyle}
                      defaultValue={brandData || []}
                      onChange={(event) => storeBrand(event)}
                      renderValue={(selected) => {
                        if (selected.length === 0) return <span style={{color: '#aaa'}}>กรุณาเลือก</span>
                        return selected.join(', ')
                      }}
                    > 
                      {brandList.map(({ Brand: brand }) => (
                        <MenuItem key={brand} value={brand}>
                          {brand}
                        </MenuItem>
                      ))}
                    </Select>
                    <IconButton
                      className="close-button"
                      aria-label="close"
                      onClick={() => setBrand([])}
                      sx={closeButtonStyle}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>

                  {/* PROJECT REFERENCE */}
                  <Grid item xs={12} md={6} sx={gridRowStyle}>
                    <Label>Project Reference :</Label>
                    <TextField
                      placeholder="Search Project reference..."
                      inputProps={{ maxLength: 100 }}
                      // onChange={(e) => storeProject(e.target.value)}
                      defaultValue={project || ''}
                      inputRef={projectReferenceRef}
                      // onBlur={(e) => storeProject(e.target.value)}
                    />
                    {/* <Label>Project Reference :</Label>
                    <Select 
                    sx={textFieldStyle}
                    defaultValue={
                      // inputSelector.isfilter3 || 
                      ''}
                    onChange={(e) => storeProject(e.target.value)}
                    displayEmpty
                    >
                      <MenuItem disabled value=''>
                        <Placeholder text="กรุณาเลือก" />
                      </MenuItem>
                      <MenuItem key={'1'} value='option value'>
                      Option1
                      </MenuItem>
                      <MenuItem key={'2'} value='option value'>
                      Option2
                      </MenuItem>
                      <MenuItem key={'3'} value='option value'>
                      Option3
                      </MenuItem>
                    </Select> */}
                  </Grid>

                  {/* BUSSINESS */}
                  <Grid item xs={12} md={6} sx={gridRowStyle}>
                    <Label>Type of Business :</Label>
                    {renderTypeOfBusiness()}
                    <IconButton
                      className="close-button"
                      aria-label="close"
                      onClick={() => setTypeOfBusiness([])}
                      sx={closeButtonStyle}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>

                  {/* <Grid item xs={12} md={12} sx={gridRowStyle}>
                    <Label>Product/Item/Material :</Label>
                    <TextField
                      placeholder="Search Product/Item/Material"
                      inputProps={{ maxLength: 100 }}
                      sx={{flex:1}}
                    />
                  </Grid> */}

                  {/* DONE MQDC */}
                  <Grid item xs={12} md={6} sx={gridRowStyle}>
                    <Label>Done with MQDC :</Label>
                    <Box>
                    <RadioGroup
                      row
                      aria-labelledby="flag-type"
                      name="filterType"
                      onChange={(e) => storeDoneFlag(e.target.value)}
                      defaultValue={doneFlag}
                    >
                      <FormControlLabel value="1" control={<Radio
                      sx={{
                        color: '#4F4F4F',
                        transform:'scale(0.75)',
                        '&.Mui-checked': {
                          color: '#4F4F4F',
                        },
                      }}
                      />} label="Yes" />
                      <FormControlLabel value="0" control={<Radio
                      sx={{
                        color: '#4F4F4F',
                        transform:'scale(0.75)',
                        '&.Mui-checked': {
                          color: '#4F4F4F',
                        },
                      }}
                      />} label="No" />
                    </RadioGroup>
                    </Box>
                  </Grid>

                  {/* PROJECT MQDC */}
                  <Grid item xs={12} md={6} sx={gridRowStyle}>
                    <Label>Project with MQDC :</Label>
                    <Select 
                      multiple
                      displayEmpty
                      sx={textFieldStyle}
                      defaultValue={projectWithMQDC || []}
                      onChange={(e) => storeProjectWithMQDC(e)}
                      renderValue={(selected) => {
                        if (selected.length === 0) return <span style={{color: '#aaa'}}>กรุณาเลือก</span>
                        return selected.join(', ')
                      }}
                    >
                      {projectList.map(({ mqdcProjectName }) => (
                        <MenuItem key={mqdcProjectName} value={mqdcProjectName}>
                          {mqdcProjectName}
                        </MenuItem>
                      ))}
                    </Select>
                    <IconButton
                      className="close-button"
                      aria-label="close"
                      onClick={() => setProjectWithMQDC([])}
                      sx={closeButtonStyle}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  
                  {/* BUTTON */}
                  <Grid item xs={12} md={12} sx={{ display:'flex', justifyContent:'flex-end', gap:'7px'}}>
                    <Button type='submit' variant="contained" endIcon={<Image src={IconSearch} height="18" />} size="small" onMouseDown={(e) => requestData()} >
                      Search
                    </Button>
                    <Button 
                      type='reset' 
                      variant="contained" 
                      sx={{backgroundColor:'#A2A2A2'}} 
                      size="small"
                      onClick={() => clearSearch()}
                    >
                      Clear
                    </Button>
                    <Button 
                      onClick={handleModalOpen}
                      type='button' variant="contained" endIcon={<FavoriteIcon sx={{color:'#fff', ml: '-12px'}} />} sx={{backgroundColor:'#FFBD52'}} size="medium" 
                    />
                  </Grid>
              </Grid>
              
              
            </MainLeft>
          </Grid>
          <Grid item xs={12} md={3} 
          // sx={{height:'100%'}}
          >
            <Box sx={{background:'#E5E5E5', borderRadius:'5px', p:1, 
            // height:'100%'
            }}
            >
              <FavWatchListBox requestData={requestData} />
            </Box>
          </Grid>
        </Grid>
      </SearchBox>
      <ContentBox>
        <Box sx={{display:'flex', justifyContent:'space-between', mb:1}}>
          <TextField
            placeholder="Filter"
            defaultValue={filter}
            inputProps={{ maxLength: 100 }}
            sx={{ width: '858px', maxWidth: '100%' }}
            onBlur={(e) => {
              if (e.target.value !== filter) {
                setFilter(e.target.value)
                requestData()
              }
            }}
            onKeyDown={(event) => {
              if(event.key === "Enter") { 
                const value = filterInputRef?.current?.value
                if (value !== filter) {
                  setFilter(value)
                  requestData()
                }
              }
            }}
            inputRef={filterInputRef}
          />
          <Button 
            type='submit' 
            variant="contained" 
            sx={{color:'#EC9509', width:'126px', border:'1px solid #EC9509', background:'#fff', paddingTop:'5px', paddingBottom:'5px', '&:hover': {background:'#fff'} }} 
            endIcon={<Image src={IconExport} height="18" />}
            onClick={download}
          >
            Export
          </Button>
        </Box>
        <TableDataGrid requestData={requestData}/>
      </ContentBox>
    </Box>

    <Dialog 
    open={openModal} 
    onClose={handleModalClose}
    sx={modalStyle}
    >
        <DialogTitle>
          <Typography component='p' sx={{m:0}}>Add new favorite</Typography>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: 'absolute',
              right: 3,
              top: 0,
              color: '#4F4F4F',
              '& svg':{ fontSize:'14px',}
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              autoFocus
              id="fav_name"
              placeholder='favorite search 2'
              inputProps={{ maxLength: 100 }}
              fullWidth
              inputRef={favNameInputRef}
              sx={{
                '& input':{height:'25px'},
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {borderWidth:'1px', borderColor:'#A2A2A2'}
             }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{display:'flex', justifyContent:'center', alignItems:'center', p:0 , mb:3}}>
          <Button variant="contained" onClick={onSubmitAddFav}>Submit</Button>
        </DialogActions>
    </Dialog>
    
    <Dialog 
    open={openModalDuplicateFavorite} 
    onClose={() => setOpenModalDuplicateFavorite(false)}
    sx={modalStyle}
    >
        <DialogTitle>
          <Typography component='p' sx={{m:0}}>Warning duplicate</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenModalDuplicateFavorite(false)}
            sx={{
              position: 'absolute',
              right: 3,
              top: 0,
              color: '#4F4F4F',
              '& svg':{ fontSize:'14px',}
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Given name was duplicated</DialogContentText>
        </DialogContent>
        <DialogActions sx={{display:'flex', justifyContent:'center', alignItems:'center', p:0 , mb:3}}>
          <Button variant="contained" onClick={() => setOpenModalDuplicateFavorite(false)}>OK</Button>
        </DialogActions>
    </Dialog>

    </>
  );
};

const Label = styled('label')(({ theme }) => ({
  fontSize:'13px',
  fontWeight: 400,
  width:'150px',
  textAlign:'right',
  lineHeight: '24px',
  paddingRight: '5px',
  color: theme.palette.primary.dark,
}));

const gridStyle = {
  display: 'flex',
  flexFlow: 'column wrap',
};

const gridRowStyle = {
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems:'center',
  '& label':{
    whiteSpace: 'nowrap',
    flexBasis: '24.5%',
    maxWidth: '24.5%'
  },
  '& label+div':{
    flex:1,
    flexBasis: '70%',
    maxWidth: '70%'
  }
};

const closeButtonStyle = {
  borderRadius: '50%',
  '& svg':{ fontSize:'12px',},
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  '&:active': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}

// const selectStyle = {
//   padding: '0 5px',
//   lineHeight: '24px',
//   height: '33px',
//   maxWidth: '100%',
//   fontSize: '14px',
//   '& div': {
//     fontSize: '14px',
//   },
// };

const textFieldStyle = {
  '& input': {
    padding: '0 5px',
    lineHeight: '24px',
    height: '24px',
    maxWidth: '100%',
    fontSize: '14px',
  },
  '&>.MuiInputBase-root>.MuiSelect-select': {
    padding: '0 0 0 5px',
    fontSize: '14px',
  },
  '& textarea': {
    padding: '0',
    // lineHeight: '24px',
    minHeight: '24px',
    fontSize: '14px',
    resize: 'vertical',
  },
};
const modalStyle = {
  '& .MuiDialog-paper': {
    minWidth:'431px',
    maxWidth:'100%',
    padding:0,
    borderRadius:'5px',
  },
  '& .MuiDialogTitle-root': {
    padding:'0 10px',
    background:'#FFBD52',
    marginBottom: '16px',
    height: '31px',
    lineHeight: '31px',
    '& .MuiTypography-root': {
      fontSize:'14px',
      fontWeight:'400',
      color:'#000',
      lineHeight: '31px',
    }
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {borderWidth:'1px!important', borderColor:'#A2A2A2!important'},
  '& .MuiButton-root': {
    width:'150px',
    height:'35px',
    backgroundColor:'#FFBD52',
    color:'#fff',
    borderRadius:'5px'
  }
  
}

// const errorBox = {
//   fontSize: '12px',
//   color: '#EB3223',
//   lineHeight: '15px',
//   marginTop: '5px',
//   minHeight: '15px',
// };

export default HomePage;
