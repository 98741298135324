import * as React from 'react';
import {styled} from '@mui/material/styles'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import FavoriteIcon from '@mui/icons-material/Favorite';
// import DvrIcon from '@mui/icons-material/Dvr';
import IconWatch from '../assets/h-ic-watchlist.png';
import Image from '../components/Image'
import { IconButton, List, ListItem, ListItemText, Button, TextField } from '@mui/material';
import { userTokenStore, useWatchlistStore, useFavoriteStore, useSearchStore } from '../store'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

export default function BoxAccordion({ requestData = (search_criteria?: string) => {} }) {
    const CustomListItemText = styled(ListItemText)(() => ({
      cursor: 'pointer'
    }))

    const AccBox = styled('div')(({theme}) => ({
        '& .MuiAccordion-root':{
            background: '#C4C4C4',
            borderRadius:'5px 5px 0 0',
            margin:'0!important',
            '& .MuiAccordionSummary-root':{
                minHeight: '28px',
                '& .MuiAccordionSummary-content':{
                    margin:0,
                    alignItems:'center',
                },
                '& p':{
                    fontSize:'14px',
                    color:'#4F4F4F',
                    margin:0,
                }
            },
            '& .MuiCollapse-root':{
                background: '#E5E5E5',
                '& p':{
                    fontSize:'14px',
                    color:'#4F4F4F',
                    margin:0,
                }
            }
        },
        '& .MuiAccordionDetails-root':{
            padding:0,
            '& ul':{
                margin: 0,
                maxHeight:'124px',
                overflow:'auto',
                '& li':{
                    margin: 0,
                    paddimg:0,
                  '& .MuiTypography-root': {
                    color:'#4F4F4F',
                  }
                }
            }
        },
        // [theme.breakpoints.down('md')]: {
        // },
        // [theme.breakpoints.up('md')]: {
        // },
    }));

  const [editDisplayName, setEditDisplayName] = React.useState<string | false>('');
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const [expanded2, setExpanded2] = React.useState<string | false>('panel2');
  const [modalContent, setModalContent] = React.useState({ name: '', type: 'favorite', id: null })
  const [modalConfirmVisible, setModalConfirmVisible] = React.useState(false)
  interface ModalConfirmContent {
    id?: string;
    type: string;
  }
  const [modalConfirmContent, setModalConfirmContent] = React.useState<ModalConfirmContent>({ type: 'favorite', id: undefined });

  const editDisplayNameInputRef = React.useRef<HTMLInputElement>(null);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const handleChange2 = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded2(newExpanded ? panel : false);
    };

  const [openModal, setOpenModal] = React.useState(false);
  const handleModalOpen = (text:any, type:any, id:any) => {
    setOpenModal(true);
    // setEditDisplayName(text);
    setModalContent((prevValue) => ({ ...prevValue, name: text, type, id }))
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };

  const token = userTokenStore((state) => state.token)

  const {
    datas: favoriteDatas,
    getDatas: getFavoriteDatas,
    deleteFavorite,
    editFavorite,
  } = useFavoriteStore(
    (state) => ({
      datas: state.datas,
      getDatas: state.getDatas,
      deleteFavorite: state.deleteFavorite,
      editFavorite: state.editFavorite,
    })
  )

  const {
    setByJson,
  } = useSearchStore(
    (state) => ({
      setByJson: state.setByJson,
    })
  )

  const {
    datas: watchlistDatas,
    setDatas,
    getDatas: getWatchlistDatas,
    deleteWatchlist,
    editWatchlist,
  } = useWatchlistStore(
    (state) => ({
      datas: state.datas,
      setDatas: state.setDatas,
      getDatas: state.getDatas,
      deleteWatchlist: state.deleteWatchlist,
      editWatchlist: state.editWatchlist
    })
  )

  React.useEffect(() => {
    // dispatch(clearDataFilter());
    getWatchlistDatas(token)
    getFavoriteDatas(token)
  }, []);

  interface WatchlistData {
    supplier_watchlist_name?: string;
    supplier_watchlist_id?: string;
  }

  interface FavoriteData {
    favorite_search_name?: string;
    favorite_search_id?: string;
    search_criteria?: string;
  }

  const [openModalDuplicate, setOpenModalDuplicate] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const onSubmitEditName = async () => {
    const { id, type, name } = modalContent
    const newName = editDisplayNameInputRef?.current?.value || name
    let check = true
    if (type === 'favorite') {
      const prvData = favoriteDatas.find(({ favorite_search_id }: FavoriteData) => favorite_search_id === id) || {}
      const payload = {
        search_name: newName,
        search_criteria: prvData.search_criteria || '',
      }
      check = await editFavorite(id, payload, token)
    } else {
      const prvData = watchlistDatas.find(({ supplier_watchlist_id }: WatchlistData) => supplier_watchlist_id === id) || {}
      const payload = {
        supplier_watchlist_name: newName,
        supplier_id: prvData.supplier_id || '',
      }
      check = await editWatchlist(id, payload, token)
    }

    if (!check) {
      setOpenModalDuplicate(true)
      setMessage('Given name was duplicated')
    } else handleModalClose()
  }

  const handleModalConfirmClose = () => {
    setModalConfirmVisible(false)
  }

  const handleDelete = () => {
    const id = modalConfirmContent.id
    if (modalConfirmContent.type === 'favorite') {
      deleteFavorite(id, token)
    } else {
      deleteWatchlist(id, token)
    }
    handleModalConfirmClose()
  }

  return (
    <>
    <AccBox>
      <Accordion elevation={0} defaultExpanded expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          // expandIcon={<AddIcon />}
          expandIcon={expanded === 'panel1'?<RemoveIcon />:<AddIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ alignItems:'center'}}
          >
          <FavoriteIcon sx={{fontSize:'14px', color:'#4F4F4F', ml:-1, mr:1}} />
          <Typography>Favorites Search</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <List dense={true}>
                {
                  favoriteDatas.map(({ favorite_search_name, favorite_search_id, search_criteria }: FavoriteData) => {
                    const name = favorite_search_name || '-'
                    return (
                      <ListItem
                        secondaryAction={
                          <>
                          <IconButton 
                            edge="end" 
                            aria-label="Edit" 
                            sx={{mr:0.3}} 
                            onClick={() => { 
                              handleModalOpen(name, 'favorite', favorite_search_id)
                            }}
                          >
                            <BorderColorIcon sx={{fontSize:'18px', color:'#4F4F4F'}} />
                          </IconButton>
                          <IconButton 
                            edge="end" 
                            aria-label="delete"
                            onClick={() => {
                              if (!!favorite_search_id) {
                                setModalConfirmContent({ id: favorite_search_id, type: 'favorite' })
                                setModalConfirmVisible(true)
                              }
                              // deleteFavorite(favorite_search_id, token)
                            }}
                          >
                            <DeleteIcon sx={{fontSize:'18px', color:'#4F4F4F'}} />
                          </IconButton>
                          </>
                        }
                        >
                        <CustomListItemText
                          primary={name}
                          onClick={() => { 
                            if (!!search_criteria) {
                              setByJson(search_criteria)
                              setTimeout(() => {
                                requestData(search_criteria)
                              }, 500)
                            }
                          }}
                        />
                      </ListItem>
                    )
                  })
                }
                

            </List>

        </AccordionDetails>
      </Accordion>
      <Accordion elevation={0} defaultExpanded expanded={expanded2 === 'panel2'} onChange={handleChange2('panel2')}>
        <AccordionSummary
          // expandIcon={<AddIcon />}
          expandIcon={expanded2 === 'panel2'?<RemoveIcon />:<AddIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ alignItems:'center'}}
          >
          {/* <DvrIcon sx={{fontSize:'16px', color:'#4F4F4F', ml:-1, mr:1}} /> */}
          <Image src={IconWatch} height="14" style={{marginLeft:'-8px', marginRight: '8px'}} />
          <Typography>Watchlist</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <List dense={true}>
                {
                  watchlistDatas.map(({ supplier_watchlist_name, supplier_watchlist_id }: WatchlistData) => {
                    const name = supplier_watchlist_name || '-'
                    return (
                      <ListItem
                        secondaryAction={
                          <>
                            <IconButton edge="end" aria-label="Edit" sx={{mr:0.3}} onClick={ () => handleModalOpen(name, 'watchlist', supplier_watchlist_id) }>
                              <BorderColorIcon sx={{fontSize:'18px', color:'#4F4F4F'}} />
                            </IconButton>
                            <IconButton 
                              edge="end" 
                              aria-label="delete" 
                              onClick={() => {
                                if (!!supplier_watchlist_id) {
                                  setModalConfirmContent({ id: supplier_watchlist_id, type: 'watchlist' })
                                  setModalConfirmVisible(true)
                                //   deleteWatchlist(supplier_watchlist_id, token)
                                }
                              }}
                            >
                              <DeleteIcon sx={{fontSize:'18px', color:'#4F4F4F'}} />
                            </IconButton>
                          </>
                        }
                        >
                        {/* <ListItemText
                          primary={name}
                        /> */}
                        <CustomListItemText
                          primary={name}
                          onClick={() => {
                            if (!!supplier_watchlist_id) {
                              const search = {
                                keyword: '',
                                vendor: '',
                                brand: [],
                                project: '',
                                typeOfBusiness: [],
                                doneFlag: '',
                                projectWithMQDC: [],
                                supplierWatchlistId: supplier_watchlist_id
                              }
                              setByJson(JSON.stringify(search))
                              setTimeout(() => {
                                requestData(JSON.stringify(search))
                              }, 500)
                            }
                          }}
                        />
                      </ListItem>
                    )
                  })
                }


                </List>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion disabled>
        <AccordionSummary
        expandIcon={<AddIcon />}
        aria-controls="panel3a-content"
        id="panel3a-header"
        >
        <Typography>Disabled Accordion</Typography>
        </AccordionSummary>
      </Accordion> */}
    </AccBox>

    <Dialog 
    open={openModal} 
    onClose={handleModalClose}
    sx={modalStyle}
    >
        <DialogTitle>
          <Typography component='p' sx={{m:0}}>Rename</Typography>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: 'absolute',
              right: 3,
              top: 0,
              color: '#4F4F4F',
              '& svg':{ fontSize:'14px',}
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              autoFocus
              id="fav_name"
              placeholder='Rename'
              // value={editDisplayName!=='' ? editDisplayName : ''}
              defaultValue={modalContent.name || ''}
              inputProps={{ maxLength: 100 }}
              fullWidth
              inputRef={editDisplayNameInputRef}
              sx={{
                '& input':{height:'25px'},
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {borderWidth:'1px', borderColor:'#A2A2A2'}
             }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{display:'flex', justifyContent:'center', alignItems:'center', p:0 , mb:3}}>
          <Button variant="contained" onClick={onSubmitEditName}>Submit</Button>
        </DialogActions>
    </Dialog>

    <Dialog 
      open={modalConfirmVisible} 
      onClose={handleModalConfirmClose}
      sx={modalStyle}
    >
        <DialogTitle>
          <Typography component='p' sx={{m:0}}>Confirm Delete</Typography>
          <IconButton
            aria-label="close"
            onClick={handleModalConfirmClose}
            sx={{
              position: 'absolute',
              right: 3,
              top: 0,
              color: '#4F4F4F',
              '& svg':{ fontSize:'14px',}
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ textAlign: 'center' }}>
            Delete {modalConfirmContent.type === 'favorite' ? 'Favorite': 'Watchlist'} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{display:'flex', justifyContent:'center', alignItems:'center', p:0 , mb:3}}>
          <Button 
            variant="contained"
            onClick={handleModalConfirmClose}
            className="cancel-btn"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleDelete}>Delete</Button>
        </DialogActions>
    </Dialog>

    <Dialog 
    open={openModalDuplicate} 
    onClose={() => setOpenModalDuplicate(false)}
    sx={modalStyle}
    >
        <DialogTitle>
          <Typography component='p' sx={{m:0}}>Warning duplicate</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenModalDuplicate(false)}
            sx={{
              position: 'absolute',
              right: 3,
              top: 0,
              color: '#4F4F4F',
              '& svg':{ fontSize:'14px',}
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{display:'flex', justifyContent:'center', alignItems:'center', p:0 , mb:3}}>
          <Button variant="contained" onClick={() => setOpenModalDuplicate(false)}>OK</Button>
        </DialogActions>
    </Dialog>
    </>
  );
}

const modalStyle = {
  '& .MuiDialog-paper': {
    minWidth:'431px',
    maxWidth:'100%',
    padding:0,
    borderRadius:'5px',
  },
  '& .MuiDialogTitle-root': {
    padding:'0 10px',
    background:'#FFBD52',
    marginBottom: '16px',
    height: '31px',
    lineHeight: '31px',
    '& .MuiTypography-root': {
      fontSize:'14px',
      fontWeight:'400',
      color:'#000',
      lineHeight: '31px',
    }
  },
  '& .cancel-btn': {
    background:'#A2A2A2 !important',
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {borderWidth:'1px!important', borderColor:'#A2A2A2!important'},
  '& .MuiButton-root': {
    width:'150px',
    height:'35px',
    backgroundColor:'#FFBD52',
    color:'#fff',
    borderRadius:'5px'
  }
  
}